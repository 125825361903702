:global {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding: calc(#{$gutter} / 2);
  }
  .custom-html {
    a {
      cursor: pointer;
      text-decoration: underline;

      &:visited { color: inherit; }
      &:active, &:focus, &:hover { color: theme("colors.theme"); }
    }
  }
  .navbar {
    flex-shrink: 0;
    min-height: 100px;

    .container {
      padding-bottom: 5px;
      padding-top: 5px;
      img {
        max-height: 90px;
        max-width: 100%;
      }
    }
  }
  .table {
    border-collapse: collapse;
    width: 100%;

    tbody tr {
      &:first-of-type td { border-width: 0px; }
      &:hover { background-color: rgba(0, 0, 0, 0.075); }
    }
    thead th {
      color: theme("colors.text.light");
      background-color: theme("colors.background.dark");
      border-bottom: theme("borderWidth.md") solid theme("colors.background.dark");
    }
    th, td {
      border-top: theme("borderWidth.sm") solid theme("colors.border");
      padding: 0.75rem;
    }
  }
  .table-responsive {
    border-radius: 0.25rem;
    overflow-x: auto;
    width: 100%;
  }
}
